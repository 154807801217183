import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useEventListener from './hooks/useEventListener'
import logo from '../assets/google-logo.png'
import { BsStarHalf, BsStarFill } from 'react-icons/bs'

const isBrowser = typeof window !== 'undefined'

function generateStars(score) {
  const result = []
  // Get the number of whole stars
  var wholeStars = Math.floor(score)
  // Do we want a half star?
  var halfStar = wholeStars < score

  // Show the stars
  for (var i = 1; i <= wholeStars; i++) {
    result.push(<BsStarFill key={i} color="#e7711b" />)
  }

  // And the half star
  if (halfStar) {
    result.push(<BsStarHalf key={i} color="#e7711b" />)
  }
  return result
}

const Wrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    display: none;
  }

  ${({ hidden }) =>
    hidden &&
    `
    opacity: 0;
    pointer-events: none;
  `}

  transition: opacity 150ms linear;
  width: 230px;
  height: 65px;
  border-top: 3px solid lightgreen;

  position: fixed;
  bottom: 10px;
  left: 150px;
  background-color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 800;

  img {
    width: 50px;
    height: 50px;
    margin: 0;
    margin-right: 10px;
  }
  svg {
    padding-right: 4px;
    &:last-child() {
      padding-right: 0;
    }
  }
  span {
    font-weight: bold;
    color: #e7711b;
    margin-right: 0.5em;
  }
  span.ratings_title {
    font-size: 14px;
    font-weight: bold;
    color: #777;
    line-height: 1;
  }
  div.ratings_score {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

function GoogleReview({ rating }) {
  const [hidden, setHidden] = useState(false)
  const handler = () => {
    if (!isBrowser) return
    const footer = document.getElementById('footer')
    const windowBottom = window.scrollY + window.innerHeight

    if (footer && footer.offsetTop < windowBottom) {
      setHidden(true)
    } else {
      setHidden(false)
    }
  }

  useEventListener('scroll', handler)
  return (
    <Wrapper hidden={hidden}>
      <img src={logo} alt="Google Rating" />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className="ratings_title">Google Rating</span>
        <div className="ratings_score">
          <span>{rating}</span>
          {generateStars(rating)}
        </div>
      </div>
    </Wrapper>
  )
}

GoogleReview.propTypes = {
  rating: PropTypes.number.isRequired
}

export default GoogleReview
